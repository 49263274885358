import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { complimentaryDocList } from 'constant/vendor'
import {
  type VendorDetailsInterface,
  type VendorFormType,
  type VendorDocumentType,
  type VendorCompanyType,
  type VendorDetailRequestInterface,
  type RevisionInfoType,
  type VendorOptionsType,
  type VendorUomsType,
  type AstroPicType,
} from 'features/Enterprise/Vendor/services/addEditVendor'

export const SLICE_NAME = 'addEditVendor'

interface AddEditVendorStateInterface {
  isLoading: boolean
  vendorDetails: VendorDetailsInterface
  form: VendorFormType
  vendorCompanies: VendorCompanyType[]
  vendorDocuments: VendorDocumentType[]
  pksDocuments: VendorDocumentType[]
  revisionInfo: RevisionInfoType
  previewDialogData: {
    isShow: boolean
    idx?: number
    type?: string
  }
  pkpType: 'pkp' | 'non-pkp'
  vendorOptions: VendorOptionsType[]
  vendorUoms: VendorUomsType[]
  astroPicList: AstroPicType[]
}

const initialState: AddEditVendorStateInterface = {
  isLoading: false,
  vendorDetails: {} as VendorDetailsInterface,
  form: {} as VendorFormType,
  revisionInfo: {} as RevisionInfoType,
  vendorCompanies: [],
  vendorDocuments: [],
  pksDocuments: [],
  previewDialogData: {
    isShow: false,
    idx: 0,
    type: '',
  },
  pkpType: 'pkp',
  vendorOptions: [],
  vendorUoms: [],
  astroPicList: [],
}

const addEditVendorSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setVendorDetails: (state, action: PayloadAction<VendorDetailsInterface>) => {
      state.vendorDetails = action.payload
    },
    setForm: (
      state,
      action: PayloadAction<{
        key: keyof VendorFormType
        value: unknown
      }>,
    ) => {
      const { key, value } = action.payload
      state.form[key] = value as never
    },
    setVendorCompany: (state, action: PayloadAction<VendorCompanyType[]>) => {
      state.vendorCompanies = action.payload.map((doc) => ({
        ...doc,
        company_code: `PT ${doc.company_code}`,
      }))
    },
    setVendorDocuments: (state, action: PayloadAction<VendorDocumentType[]>) => {
      state.vendorDocuments = action.payload
    },
    setVendorDetailRequest: (state, action: PayloadAction<VendorDetailRequestInterface>) => {
      const { request_data } = action.payload

      const pksDocs = request_data.documents?.filter((doc) => doc.document_type === 'pks') || []
      const nonPkpDocs =
        request_data.documents
          ?.filter((doc) => doc.document_type === 'sp_non_pkp')
          ?.map((doc) => ({
            ...doc,
            label: 'Surat Pernyataan Non-PKP',
          })) || []
      const nonPksDocs = complimentaryDocList(request_data.vendor_type).map((compDoc) => {
        const doc = request_data?.documents?.find((x) => x.document_type === compDoc.document_type)
        if (doc) {
          return {
            ...doc,
            label: compDoc.label,
            required: compDoc.required,
          }
        }

        return compDoc
      })

      state.form = {
        ...request_data,
        vendor_bank_accounts: request_data?.vendor_bank_accounts?.map((bank) => ({
          ...bank,
          form_id: window.crypto.randomUUID(),
        })),
        vendor_general_contacts: request_data?.vendor_general_contacts?.map((contact) => ({
          ...contact,
          form_id: window.crypto.randomUUID(),
        })),
        documents: [...pksDocs, ...nonPkpDocs, ...nonPksDocs] as VendorDocumentType[],
        state: request_data.vendor_locations[0].state,
        postal_code: request_data.vendor_locations[0].postal_code,
        address: request_data.vendor_locations[0].address,
        country: request_data.vendor_locations[0].country,
        address_label: request_data.vendor_locations[0].address_label,
        city: request_data.vendor_locations[0].city,
      }
      state.revisionInfo = action.payload
      state.vendorDetails = {
        ...request_data,
        documents: [
          ...pksDocs.filter((x) => x.file_path.length),
          ...nonPkpDocs.filter((x) => x.file_path.length),
          ...nonPksDocs.filter((x) => x.file_path.length),
        ] as VendorDocumentType[],
      }
    },
    resetForm: (state) => {
      state.form = initialState.form
      state.revisionInfo = initialState.revisionInfo
      state.vendorDetails = initialState.vendorDetails
    },
    setPreviewDialogData: (
      state,
      action: PayloadAction<AddEditVendorStateInterface['previewDialogData']>,
    ) => {
      state.previewDialogData = action.payload
    },
    setPkpType: (state, action: PayloadAction<'pkp' | 'non-pkp'>) => {
      state.pkpType = action.payload
    },
    setVendorOptions: (state, action: PayloadAction<VendorOptionsType[]>) => {
      state.vendorOptions = action.payload
    },
    setVendorUoms: (state, action: PayloadAction<VendorUomsType[]>) => {
      state.vendorUoms = action.payload
    },
    setAstroPicList: (state, action: PayloadAction<AstroPicType[]>) => {
      state.astroPicList = action.payload
    },
  },
})

export const {
  setLoading,
  setVendorDetails,
  setForm,
  setVendorCompany,
  setVendorDocuments,
  setVendorDetailRequest,
  resetForm,
  setPreviewDialogData,
  setPkpType,
  setVendorOptions,
  setVendorUoms,
  setAstroPicList,
} = addEditVendorSlice.actions

export default addEditVendorSlice.reducer
